<template>
  <div>
    <Table
      label="Game"
      :actions="actions"
      :items="$store.getters['Game/tableData']"
      :fields="fields"
      :loading="$store.state.Game.loadingTable"
      :filter="$store.state.Game.tableFilter"
      :sort="$store.state.Game.tableSort"
      :sort-options="sortOptions"
      @filter="filterData"
      @sort="sortData"
      @load="loadData"
    />
  </div>
</template>

<script>
import Table from '@/layouts/components/Table.vue'

export default {
  components: {
    Table,
  },
  data() {
    return {
      // Sort
      sortOptions: [
        {
          key: 'createdAt', direction: 'desc', label: 'Newest', default: true,
        },
        { key: 'createdAt', direction: 'asc', label: 'Oldest' },
      ],

      // Fields
      fields: [
        {
          key: 'id',
          type: 'id',
          label: 'ID',
        },
        {
          key: 'image',
          label: 'Cover',
          type: 'image',
        },
        {
          key: 'name',
          label: 'Title',
          type: 'profile',
          image: 'icon',
        },
        {
          key: 'active_status',
          label: 'Status',
          type: 'dot',
          map: {
            active: 'success',
            inactive: 'danger',
          },
        },
        {
          key: 'platform',
          label: 'Platform',
          type: 'badges',
          map: {},
        },
        {
          key: 'gameSpecs',
          label: 'Specialization',
          type: 'badges',
          map: {},
        },
        {
          key: 'sort',
          label: 'Order',
          prefix: '#',
        },
        {
          key: 'createdAt',
          label: 'Created at',
          type: 'datetime',
        },
        {
          key: 'updatedAt',
          label: 'Updated at',
          type: 'datetime',
          visibility: ['view'],
        },
      ],

      actions: ['view', 'create', 'update'],
    }
  },
  methods: {
    loadData() {
      return this.$store.dispatch('Game/getTableData')
    },
    filterData(val) {
      this.$store.commit('Game/SET_TABLE_FILTER', val)
    },
    sortData(val) {
      this.$store.commit('Game/SET_TABLE_SORT', val)
    },
  },
}
</script>
